import Swiper, { Autoplay, Navigation, Thumbs } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('gallery-section__sliders')[0]) {
  const gallerySlidersArr = document.querySelectorAll(
    '.gallery-section__sliders'
  );
  gallerySlidersArr.forEach((slidersEl, sliderIdx) => {
    const mainSliderEl = slidersEl.querySelector(
      '.gallery-section__main-slider'
    );
    const navSliderEl = slidersEl.querySelector('.gallery-section__nav-slider');
    const pseudoNavEl = slidersEl.querySelector(
      '.gallery-section__main-slider-nav'
    );
    const toggleNavElsArr = pseudoNavEl.querySelectorAll('.slider-toggle');

    const slides = mainSliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 5) {
      loop = true;
      loopedSlides = slides.length * 5;
    }

    const navSwiper = new Swiper(navSliderEl, {
      modules: [Autoplay, Navigation, Thumbs],
      slidesPerView: 3,
      spaceBetween: 6,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      /*slideToClickedSlide: true,*/

      breakpoints: {
        426: {
          spaceBetween: 10,
          slidesPerView: 3,
        },
        641: {
          spaceBetween: 10,
          slidesPerView: 4,
        },
        901: {
          spaceBetween: 10,
          slidesPerView: 6,
        },
        /*1025: {
          spaceBetween: 8,
          slidesPerView: 7,
        },
        1250: {
          spaceBetween: 9,
          slidesPerView: 8,
        },*/
      },
    });

    const mainSwiper = new Swiper(mainSliderEl, {
      modules: [Autoplay, Navigation, Thumbs],
      slidesPerView: 1,
      spaceBetween: 0,
      threshold: 10,
      speed: 800,
      slideToClickedSlide: true,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: slidersEl.querySelector('.slider-btn.prev'),
        nextEl: slidersEl.querySelector('.slider-btn.next'),
      },
      thumbs: {
        swiper: navSwiper,
      },

      breakpoints: {
        641: {
          spaceBetween: 20,
        },
        901: {
          spaceBetween: 26,
        },
      },
    });
    function switchToNextSlide(offset) {
      const activeIndex = mainSwiper.activeIndex;

      const nextSlideIndex = (activeIndex + offset) % mainSwiper.slides.length;

      const nextSlide = mainSwiper.slides[nextSlideIndex];
      mainSwiper.slideTo(nextSlideIndex);

      /*if (nextSlide !== nextSlide.parentElement.firstElementChild) {
        mainSwiper.slideTo(nextSlideIndex);
      }*/
    }

    function addClassOnHover(button, classToAdd, offset) {
      button.addEventListener('mouseenter', () => {
        const activeIndex = mainSwiper.activeIndex;
        const targetIndex = (activeIndex + offset) % mainSwiper.slides.length;
        const targetSlide = mainSwiper.slides[targetIndex];
        targetSlide.classList.add(classToAdd);
        const totalSlides = mainSwiper.slides.length;

        const activeSlide = mainSwiper.slides[activeIndex];
        const prevSlide =
          mainSwiper.slides[(activeIndex - 1 + totalSlides) % totalSlides];
        const nextSlide = mainSwiper.slides[targetIndex];

        /*let hasPrevSlides = false;
        let nextIndex = (targetIndex + 1) % totalSlides;

        while (nextIndex !== activeIndex) {
          const nextSibling = mainSwiper.slides[nextIndex];
          if (nextSibling.classList.contains('swiper-slide-prev')) {
            hasPrevSlides = true;
            break;
          }
          nextIndex = (nextIndex + 1) % totalSlides;
        }

        if (!hasPrevSlides) {
          console.log('test');
        }*/

        /*if (targetIndex !== slides.length) {
          console.log('test');
        }*/

        /*if (targetSlide !== targetSlide.parentElement.firstElementChild) {
          targetSlide.classList.add(classToAdd);
        }*/
      });

      button.addEventListener('mouseleave', () => {
        const activeIndex = mainSwiper.activeIndex;
        const targetIndex = (activeIndex + offset) % mainSwiper.slides.length;
        const targetSlide = mainSwiper.slides[targetIndex];
        targetSlide.classList.remove(classToAdd);

        /*if (targetSlide !== targetSlide.parentElement.firstElementChild) {
          targetSlide.classList.remove(classToAdd);
        }*/
      });
    }

    toggleNavElsArr[0].addEventListener('click', () => {
      switchToNextSlide(1);
    });

    toggleNavElsArr[1].addEventListener('click', () => {
      switchToNextSlide(2);
    });

    toggleNavElsArr[2].addEventListener('click', () => {
      switchToNextSlide(3);
    });

    addClassOnHover(toggleNavElsArr[0], 'hovered-slide', 1);
    addClassOnHover(toggleNavElsArr[1], 'hovered-slide', 2);
    addClassOnHover(toggleNavElsArr[2], 'hovered-slide', 3);

    swiperObserver(mainSwiper);
  });
}
